import React from "react";

const AttendanceDataSVG = ({ color, ...otherProps }) => {
  return (
    <svg
      className="w-6 h-6"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      stroke="current-color"
    >
      <path
        d="M2.06177 6.76833H13.9444"
        stroke={color}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M10.9614 9.3699H10.9675"
        stroke={color}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8.0031 9.3699H8.00928"
        stroke={color}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M5.03862 9.3699H5.0448"
        stroke={color}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M10.9614 11.9636H10.9675"
        stroke={color}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8.0031 11.9636H8.00928"
        stroke={color}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M5.03862 11.9636H5.0448"
        stroke={color}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M10.6958 1.83203V4.02588"
        stroke={color}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M5.31039 1.83203V4.02588"
        stroke={color}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.8255 2.88672H5.18064C3.22285 2.88672 2 3.97734 2 5.98207V12.0152C2 14.0514 3.22285 15.1673 5.18064 15.1673H10.8193C12.7833 15.1673 14 14.0703 14 12.0656V5.98207C14.0062 3.97734 12.7895 2.88672 10.8255 2.88672Z"
        stroke={color}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default AttendanceDataSVG;
