import React from "react";
import {
  InputSelect,
  Button,
  InputDate,
  InputTime,
  ModalDialog,
  TextArea,
} from "@bluesilodev/timhutcomponents";
import { useFormikContext } from "formik";
import { useSelector } from "react-redux";
import { useFetchShiftByUserIDAndDateQuery } from "store/apis/externalApi";

const convertDate = (dateStr) => {
  const [dd, mm, yyyy] = dateStr.split("/");
  return `${yyyy}-${mm}-${dd}`;
}

export const AttendanceApprovalRequestAttendanceModalForm = ({
  open: isModalOpen,
  handleClose: handleCloseModal,
}) => {
  const { currentUser } = useSelector((state) => state.userData);
  const formik = useFormikContext();
  const { data: dataShift, error: errorShift, isFetching: isFetchingShift } = useFetchShiftByUserIDAndDateQuery({
    fetchShiftByUserID: currentUser?.uId || "",
    startDate: formik.values.punchInDate ? convertDate(formik.values.punchInDate) : "2020-01-01",
    endDate: formik.values.punchInDate ? convertDate(formik.values.punchInDate) : "2020-01-01",
  }, {
    refetchOnMountOrArgChange: true,
  });
  const label = <span className="text-gray-500 text-xs">notes</span>;
  return (
    <React.Fragment>
      {isModalOpen && (
        <ModalDialog
          title="Request Attendance Changes"
          onClose={handleCloseModal}
          onSubmit={formik.handleSubmit}
          className={"w-3/4 p-6 max-h-[90%]"}
        >
          <div className="grid grid-cols-3 gap-6">
            <div className="col-span-1">
              <InputSelect
                title={"Locations"}
                options={[{ label: "Cafe Halim", value: "Cafe Halim" }]}
                value={"Cafe Halim"}
                disabled={true}
                classname={"h-[58px]"}
              />
            </div>
            <div className="col-span-1">
              <InputSelect
                title={"Departments"}
                options={[{ label: "Operations", value: "Operations" }]}
                value={"Operations"}
                disabled={true}
                classname={"h-[58px]"}
              />
            </div>
            <div className="col-span-1">
              <InputSelect
                title={"Job Positions"}
                options={[{ label: "Supervisor", value: "Supervisor" }]}
                value={"Supervisor"}
                disabled={true}
                classname={"h-[58px]"}
              />
            </div>
            <div className="col-span-1 row-span-2">
              <InputSelect
                title={"Employee Name"}
                options={[{ label: "Supervisor", value: "Supervisor" }]}
                value={"Supervisor"}
                disabled={true}
                classname={"h-[58px]"}
              />
            </div>
            <div className="col-span-1">
              <InputDate
                label={"Punch In Date"}
                name={"punchInDate"}
                value={formik.values.punchInDate}
                setFieldValue={(_, val) => formik.setFieldValue("punchInDate", val)}
              />
            </div>
            <div className="col-span-1">
              <InputTime
                label={"Punch In Time"}
                name="punchInTime"
                id="punchInTime"
                value={formik.values.punchInTime}
                setFieldValue={(_, val) => formik.setFieldValue("punchInTime", val)}
              />
            </div>
          </div>
          <div className="grid grid-cols-3 gap-6">
            <div className="col-span-1">
              <InputSelect
                title={"Shift"}
                options={[...(dataShift?.assignShiftsByDate?.[0]?.shifts ? dataShift?.assignShiftsByDate?.[0]?.shifts?.map((val) => ({ label: val.shift.shiftName, value: val.shift.uId })) : [])]}
                classname={"h-[58px]"}
                name={"scheduleID"}
                value={"Office Working Hour"}
                disabled={false}
                onChange={formik.handleChange}
              />
            </div>
            <div className="col-span-1">
              <InputDate
                label={"Punch Out Date"}
                name={"punchOutDate"}
                value={formik.values.punchOutDate}
                setFieldValue={(_, val) => formik.setFieldValue("punchOutDate", val)}
              />
            </div>
            <div className="col-span-1">
              <InputTime
                label={"Punch Out Time"}
                name={"punchOutTime"}
                value={formik.values.punchOutTime}
                setFieldValue={(_, val) => formik.setFieldValue("punchOutTime", val)}
              />
            </div>
          </div>
          <div className="grid grid-cols-3 gap-6 mt-6">
            <div className="col-span-3 row-span-2">
              <TextArea label={label} name="notes" value={formik.values.notes} onChange={formik.handleChange} />
              <div className="text-red-500">{formik.errors.notes}</div>
            </div>
          </div>
          <div className="flex justify-between mt-6">
            <Button
              label={"Cancel"}
              onClick={handleCloseModal}
              className={"flex-grow mr-2"}
            />
            <Button
              label={"Request Attendance"}
              onClick={formik.handleSubmit}
              className={"flex-grow"}
              style="solid"
            />
          </div>
        </ModalDialog>
      )}
    </React.Fragment>
  );
};
