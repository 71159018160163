import React from "react";

const CircleCheckSVG = ({ color, ...otherProps }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_5427_28452)">
        <path
          d="M14.6666 7.38723V8.00056C14.6658 9.43818 14.2003 10.837 13.3395 11.9884C12.4787 13.1399 11.2688 13.9822 9.89016 14.3898C8.51154 14.7974 7.03809 14.7485 5.68957 14.2503C4.34104 13.7521 3.18969 12.8313 2.40723 11.6253C1.62476 10.4192 1.25311 8.9926 1.3477 7.5581C1.44229 6.1236 1.99806 4.75811 2.93211 3.66528C3.86615 2.57244 5.12844 1.81082 6.53071 1.49399C7.93298 1.17717 9.4001 1.32212 10.7133 1.90723"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke={color}
        />
        <path
          d="M14.6667 2.66602L8 9.33935L6 7.33935"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_5427_28452">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default CircleCheckSVG;
