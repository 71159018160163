import React from "react";
import {
  InputSelect,
  InputSelectSearch,
  Button,
  InputDate,
  InputTime,
  ModalDialog,
  TextArea,
} from "@bluesilodev/timhutcomponents";
import { useFormikContext } from "formik";
import { useSelector } from "react-redux";
import { useFetchShiftByUserIDAndDateQuery, useFetchShiftByUserIDQuery } from "store/apis/externalApi";

const convertDate = (date) => {
  const [dd, mm, yyyy] = [String(date.getUTCDate()).padStart(2, '0'), String(date.getUTCMonth() + 1).padStart(2, '0'), date.getUTCFullYear()];
  return `${yyyy}-${mm}-${dd}`;
}

export const AttendanceOvertimeRequestOvertimeModalForm = ({
  open: isModalOpen,
  handleClose: handleCloseModal,
}) => {
  const { currentUser } = useSelector((state) => state.userData);
  const formik = useFormikContext();
  const { data: dataShift, error: errorShift, isFetching: isFetchingShift } = useFetchShiftByUserIDQuery({
    fetchShiftByUserID: currentUser?.uId || "",
    // startDate: formik.values.attendanceDate ? convertDate(formik.values.attendanceDate) : "2020-01-01",
    // endDate: formik.values.attendanceDate ? convertDate(formik.values.attendanceDate) : "2020-01-01",
  }, {
    refetchOnMountOrArgChange: true,
  });
  const label = <span className="text-gray-500 text-xs">Notes</span>;
  const setDigit = (number) => {
    if (number > 9) return number
    else return `0${number}`
  }
  return (
    <React.Fragment>
      {isModalOpen && (
        <ModalDialog
          title="Request Overtime"
          onClose={handleCloseModal}
          onSubmit={formik.handleSubmit}
          className={"w-3/4 p-6 max-h-[90%]"}
        >
          <div className="grid grid-cols-3 gap-6">
            <div className="col-span-1">
              <InputSelect
                title={"Locations"}
                options={[{ label: "Cafe Halim", value: "Cafe Halim" }]}
                value={"Cafe Halim"}
                disabled={true}
                classname={"h-[58px]"}
              />
            </div>
            <div className="col-span-1">
              <InputSelect
                title={"Departments"}
                options={[{ label: "Operations", value: "Operations" }]}
                value={"Operations"}
                disabled={true}
                classname={"h-[58px]"}
              />
            </div>
            <div className="col-span-1">
              <InputSelect
                title={"Job Positions"}
                options={[{ label: "Supervisor", value: "Supervisor" }]}
                value={"Supervisor"}
                disabled={true}
                classname={"h-[58px]"}
              />
            </div>
            <div className="col-span-1">
              <InputSelect
                title={"Employee Name"}
                options={[{ label: "Supervisor", value: "Supervisor" }]}
                value={"Supervisor"}
                disabled={true}
                classname={"h-[58px]"}
              />
            </div>
            <div className="col-span-1">
              <InputSelect
                title={"Shift"}
                options={[...(dataShift?.assignShifts ? dataShift.assignShifts.reduce((acc, val) => {
                  if (!acc.some((item) => item.value === val.shift.uId)) {
                    acc.push({ label: val.shift.shiftName, value: val.shift.uId });
                  }
                  return acc;
                }, []) : [])]}
                value={"Office Working Hour"}
                name={"scheduleID"}
                disabled={false}
                classname={"h-[58px]"}
                onChange={formik.handleChange}
              />
            </div>
            <div className="col-span-1">
              <InputSelectSearch
                title={"Punch In Date"}
                options={formik.values.attendanceOption || []}
                value={formik.values.attendanceID}
                classname={"h-[58px]"}
                errors={formik.errors.attendanceID}
                onChange={(e) => {
                  const findData = formik.values.attendanceOption.find((val) => val.attendanceID === e);
                  const punchIn = new Date(findData.punchIn);
                  const punchOut = new Date(findData.punchOut);
                  formik.setFieldValue("attendanceID", e);
                  formik.setFieldValue("attendanceDate", punchIn);
                  formik.setFieldValue("attendancePunchInTime", `${punchIn.getHours()}:${punchIn.getMinutes()}`);
                  formik.setFieldValue("attendancePunchOutTime", `${punchOut.getHours()}:${punchOut.getMinutes()}`);
                  formik.setFieldValue("overtimeDate", punchIn);
                }}
              />
              {/*
              <InputDate
                label={"Punch In Date"}
                value={`${setDigit(formik.values.overtimeDate.getDate())}/${setDigit(formik.values.overtimeDate.getMonth() + 1)}/${formik.values.overtimeDate.getFullYear()}`}
                errors={formik.errors.attendanceID}
                setFieldValue={(_, event) => {
                  const splitDate = event.split("/");
                  const convertToDate = new Date(splitDate[2], splitDate[1] - 1, splitDate[0]);
                  formik.setFieldValue("overtimeDate", convertToDate);
                }}
              />
              */}
            </div>
            <div className="col-span-1">
              <InputTime label={"Punch In Time"} disable={true} value={formik.values.attendancePunchInTime} />
            </div>
            <div className="col-span-1">
              <InputTime label={"Punch Out Time"} disable={true} value={formik.values.attendancePunchOutTime} />
            </div>
            <div className="col-span-1">
              <InputTime label={"Duration Of Overtime"} value={formik.values.overtimeDuration} setFieldValue={(_, val) => formik.setFieldValue("overtimeDuration", val)} />
            </div>
            <div className="col-span-3 row-span-2">
              <TextArea label={label} name="notes" value={formik.values.notes} onChange={formik.handleChange} />
              <div className="text-red-500">{formik.errors.notes}</div>
            </div>
          </div>
          <div className="flex justify-between mt-6">
            <Button
              label={"Cancel"}
              onClick={handleCloseModal}
              className={"flex-grow mr-2"}
            />
            <Button
              label={"Request Overtime"}
              onClick={(e) => !formik.errors.overtimeDate && formik.handleSubmit(e)}
              className={"flex-grow "}
              style="solid"
            />
          </div>
        </ModalDialog>
      )}
    </React.Fragment>
  );
};
