// AppRouter.js
import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Layout from "layout/index";
import AttendanceData from "pages/AttendanceData";
import EditAttendance from "pages/AttendanceData/editAttendancePage";
import AttendanceOvertime from "pages/AttendanceOvertime";
import OvertimeApproval from "pages/AttendanceOvertime/overtimeApproval";
import AttendanceApproval from "pages/AttendanceRequest";
import ApprovalPage from "pages/AttendanceRequest/approvalPage";
import AttendanceSetting from "pages/AttendanceSetting";

const AppRouter = () => {
  return (
    <Router basename={window.__POWERED_BY_QIANKUN__ ? '/attendance' : '/'}>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<AttendanceData />} />
          <Route path="attendance-data/:userID/:uID" element={<EditAttendance />} />
          <Route path="edit-attendance" element={<EditAttendance />} />
          <Route path="attendance-approval" element={<AttendanceApproval />}>
            <Route path="approval/:userID/:uID" element={<ApprovalPage />} />
          </Route>
          <Route path="attendance-overtime" element={<AttendanceOvertime />}>
            <Route path="overtime-approval/:userID/:uID" element={<OvertimeApproval />} />
          </Route>
          <Route path="attendance-setting" element={<AttendanceSetting />} />
        </Route>
      </Routes>
    </Router>
  );
};

export default AppRouter;
