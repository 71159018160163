import React, { useState } from "react";
import {
  Button,
  Accordion,
  InputSelect,
  InputDate,
  InputTime,
  TextArea,
  InputText,
  ModalDialog,
} from "@bluesilodev/timhutcomponents";
import { FormikProvider, useFormik } from "formik";
import { useSelector } from "react-redux";
import { Outlet, useParams } from "react-router-dom";
import CircleCheckSVG from "assets/icon/CircleCheckSVG/CircleCheckSVG";
import CircleXMarxSVG from "assets/icon/CircleXMarkSVG/CircleXMarkSVG";
import XMarkSVG from "assets/icon/XMarkSVG/XMarkSVG";
import ProfileImage from "assets/images/profile.jpeg";
import FetchingAndError from "components/fetchingAndError";
import { useAlertApi } from "services/alert-api";
import { useFetchSingleAttendanceOvertimeQuery, useUpdateAttendanceOvertimeMutation } from "store/apis/attendanceOvertimeApi";
import { useFetchShiftByUserIDQuery } from "store/apis/externalApi";

const OvertimeApproval = () => {
  let { userID, uID } = useParams();
  // Redux State
  const { currentRole } = useSelector((state) => state.userData);
  // Element State
  const [isEnter, setEnter] = useState("#DD7224");
  const [isModalOpen, setIsModalOpen] = useState(false);
  // Redux Toolkit
  const { data: dataShift, error: errorShift, isFetching: isFetchingShift } = useFetchShiftByUserIDQuery({ fetchShiftByUserID: userID || "" }, { refetchOnMountOrArgChange: true });
  const { data: dataOvertime, error: errorAttendanceOvertime, isFetching: isFetchingAttendanceOvertime } = useFetchSingleAttendanceOvertimeQuery({ id: uID }, { refetchOnMountOrArgChange: true });
  const [approveRejectAttendanceOvertime, approveRejectAttendanceOvertimeResult] = useUpdateAttendanceOvertimeMutation();
  // Formik
  const formik = useFormik({
    initialValues: {
      employeeDetail: {
        photo: "",
        shift: "",
        firstName: "",
        lastName: "",
        userName: "",
        employeeID: "",
        userID: userID,
        location: "",
        department: "",
        jobPosition: "",
      },
      attendanceDetail: {
        overtimeDate: "01/01/90",
        punchInTime: "00:00",
        punchOutTime: "00:00",
      },
      overtimeDetail: {
        compensationType: "",
        overtimeDuration: "",
        notes: "",
        approvalStatus: {
          byHr: {
            status: "",
            comment: "",
          },
          bySupervisor: {
            status: "",
            comment: "",
          },
        },
        // Extra
        rejectReason: "",
      },
    },
    validationSchema: undefined,
    onSubmit: (values) => {
    },
  });

  React.useEffect(() => {
    if (dataOvertime) {
      const overtimeDate = new Date(dataOvertime.data.overtimeDate);
      const punchInTime = new Date(dataOvertime.data.attendanceDetails.punchIn);
      const punchOutTime = new Date(dataOvertime.data.attendanceDetails.punchOut);
      formik.setFieldValue("employeeDetail", {
        photo: dataOvertime.data.employeeDetails?.photo?.[0]?.link || "",
        shift: dataOvertime.data.attendanceDetails?.scheduleID || "-",
        firstName: dataOvertime.data.employeeDetails?.firstName || "",
        lastName: dataOvertime.data.employeeDetails?.lastName || "",
        userName: dataOvertime.data.employeeDetails?.userName || "",
        employeeID: dataOvertime.data.employeeDetails?.employeeID || "",
        userID: userID,
        location: dataOvertime.data.employeeDetails?.locations?.address || "",
        department: dataOvertime.data.employeeDetails?.userInformation?.employeementDetail?.departments || "",
        jobPosition: dataOvertime.data.employeeDetails?.userInformation?.employeementDetail?.jobPosition || "",
      });
      formik.setFieldValue("attendanceDetail", {
        overtimeDate: `${overtimeDate.getFullYear()}/${overtimeDate.getMonth() + 1}/${overtimeDate.getDate()}`,
        punchInTime: `${punchInTime.getHours()}:${punchInTime.getMinutes()}`,
        punchOutTime: `${punchOutTime.getHours()}:${punchOutTime.getMinutes()}`,
      });
      formik.setFieldValue("overtimeDetail", {
        compensationType: "TODO",
        overtimeDuration: dataOvertime.data.overtimeDuration,
        notes: dataOvertime.data.notes,
        approvalStatus: dataOvertime.data.approvalStatus,
        actionDisable: dataOvertime.data.actionDisable,
        // Extra
        rejectReason: "",
      });
    }
  }, [dataOvertime]);

  React.useEffect(() => {
    if (dataShift && dataShift.assignShifts) {
      const findShift = dataShift?.assignShifts.find((val) => val.shift.uId === formik.values.employeeDetail.shift)?.shift?.shiftName;
      findShift && formik.setFieldValue("employeeDetail.shift", findShift || "-");
    }
  }, [dataShift, formik.values.employeeDetail.shift]);

  useAlertApi(approveRejectAttendanceOvertimeResult);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleModalSubmit = () => {
    handleCloseModal();
    clickRejectAttendanceOvertime();
  };

  const clickApproveAttendanceOvertime = () => {
    approveRejectAttendanceOvertime({
      attendanceOvertimeID: uID,
      ...(formik.values.overtimeDetail.approvalStatus.bySupervisor.status === "pending" && {
        bySupervisor: {
          status: "approved",
          message: "Ok",
        },
      }),
      ...(formik.values.overtimeDetail.approvalStatus.bySupervisor.status === "approved" && formik.values.overtimeDetail.approvalStatus.byHr.status === "pending" && {
        byHr: {
          status: "approved",
          message: "Ok",
        },
      }),
    });
  };

  const clickRejectAttendanceOvertime = () => {
    approveRejectAttendanceOvertime({
      attendanceOvertimeID: uID,
      ...(formik.values.overtimeDetail.approvalStatus.bySupervisor.status === "pending" && {
        bySupervisor: {
          status: "rejected",
          comments: formik.values.overtimeDetail.rejectReason,
        },
      }),
      ...(formik.values.overtimeDetail.approvalStatus.bySupervisor.status === "approved" && formik.values.overtimeDetail.approvalStatus.byHr.status === "pending" && {
        byHr: {
          status: "rejected",
          comments: formik.values.overtimeDetail.rejectReason,
        },
      }),
    });
  };

  const approvalStatus = () => {
    if (!formik.values.overtimeDetail.approvalStatus) return;
    const supervisorStatus = formik.values.overtimeDetail.approvalStatus.bySupervisor.status;
    const hrStatus = formik.values.overtimeDetail.approvalStatus.byHr.status;
    if (!formik.values.overtimeDetail.actionDisable && ["Admin", "Supervisor"].includes(currentRole) && ((supervisorStatus === "pending" && currentRole === "Supervisor") || (supervisorStatus === "approved" && hrStatus === "pending" && currentRole === "Admin"))) {
      return (
        <div className="flex flex-row items-end gap-2">
          <Button
            label={
              <div className="flex px-4 gap-1">
                <CircleXMarxSVG color={"white"} />
                <div>Reject</div>
              </div>
            }
            style={"solid"}
            onClick={handleOpenModal}
            className={"w-[230px]"}
            backgroundColor={"#DD4848"}
          />
          <Button
            label={
              <div className="flex px-4 gap-1">
                <CircleCheckSVG color={"white"} />
                <div>
                  <span>Approve</span>
                  {formik.values.overtimeDetail.approvalStatus.bySupervisor.status === "pending" && " By Supervisor"}
                  {formik.values.overtimeDetail.approvalStatus.bySupervisor.status === "approved" && formik.values.overtimeDetail.approvalStatus.byHr.status === "pending" && " By Admin"}
                </div>
              </div>
            }
            style={"solid"}
            className={"w-[230px]"}
            backgroundColor={"#4BD394"}
            onClick={clickApproveAttendanceOvertime}
          />
        </div>
      );
    } else if (supervisorStatus === "rejected" || hrStatus === "rejected") {
      return (
        <div className="flex flex-row items-end gap-2">
          <div className="self-center text-red-500 font-bold text-right">
            Supervisor: {formik.values.overtimeDetail.approvalStatus.bySupervisor.comment}
            <br />
            Admin: {formik.values.overtimeDetail.approvalStatus.byHr.comment}
          </div>
          <Button
            label={
              <div className="flex px-4 gap-1">
                <CircleXMarxSVG color={"white"} />
                <div>Rejected</div>
              </div>
            }
            style={"solid"}
            className={"w-[230px]"}
            backgroundColor={"#FFA5A5"}
            disabled
          />
        </div>
      );
    } else if (supervisorStatus === "approved" && hrStatus === "approved") {
      return (
        <div className="flex flex-row items-end gap-2">
          <Button
            label={
              <div className="flex px-4 gap-1">
                <CircleCheckSVG color={"white"} />
                <div>Approved</div>
              </div>
            }
            style={"solid"}
            className={"w-[230px]"}
            backgroundColor={"#A5F2CE"}
            disabled
          />
        </div>
      );
    } else if (supervisorStatus === "approved" && hrStatus === "pending" && currentRole === "Supervisor") {
      return (
        <div className="flex flex-row items-end gap-2">
          <Button
            label={
              <div className="flex px-4 gap-1">
                <CircleCheckSVG color={"white"} />
                <div>Approved</div>
              </div>
            }
            style={"solid"}
            className={"w-[230px]"}
            backgroundColor={"#A5F2CE"}
            disabled
          />
        </div>
      );
    } else {
      return (
        <div className="flex flex-row items-end gap-2">
          <Button
            label={
              <div className="flex px-4 gap-1">
                <div>
                  Pending
                  {formik.values.overtimeDetail.approvalStatus.bySupervisor.status === "pending" && " (Supervisor)"}
                  {formik.values.overtimeDetail.approvalStatus.bySupervisor.status === "approved" && formik.values.overtimeDetail.approvalStatus.byHr.status === "pending" && " (Admin)"}
                </div>
              </div>
            }
            style={"solid"}
            className={"w-[230px]"}
            backgroundColor={"#D4D4D4"}
            disabled
          />
        </div>
      );
    }
  };

  return (
    <div className="main p-1 mt-5">
      {isModalOpen && (
        <div className="w-full  bg-white/30">
          <div class="w-full fixed z-[10] inset-0 bg-black opacity-50"></div>
          <ModalDialog
            title="Reject Attendance Overtime"
            onClose={handleCloseModal}
            onSubmit={handleModalSubmit}
            className={"max-h-[90%]"}
          >
            <h1 className="font-semibold mb-2">Add Note to your response</h1>
            <TextArea label={"Reason"} rows={5} name="overtimeDetail.rejectReason" onChange={formik.handleChange} />

            <div className="flex flex-row gap-2 justify-center mt-5">
              <Button
                onMouseEnter={() => {
                  setEnter("white");
                }}
                onMouseLeave={() => {
                  setEnter("#DD7224");
                }}
                label={
                  <div className="flex px-4 gap-1">
                    <XMarkSVG color={isEnter} />
                    <div>Cancel</div>
                  </div>
                }
                onClick={handleCloseModal}
                className={"w-[200px]"}
              />

              <Button
                label={
                  <div className="flex px-4 gap-1">
                    <CircleXMarxSVG color={"white"} />
                    <div>Reject</div>
                  </div>
                }
                onClick={handleModalSubmit}
                className={"w-[200px] bg-red-600"}
                backgroundColor={"#DD4848"}
                style={"solid"}
              />
            </div>
          </ModalDialog>
        </div>
      )}
      <FetchingAndError isFetching={isFetchingAttendanceOvertime} isError={errorAttendanceOvertime}>
        <div className="flex justify-between">
          <div className="flex items-center font-semibold">
            <p>Requested On 24 August 2023, 13:03</p>
          </div>
          {approvalStatus()}
        </div>
        {/* Employee Detail */}
        <div className="mt-8">
          <Accordion title={<div>Employee Details</div>}>
            <div className="flex">
              <div className="self-center border border-[#A3A3AB] rounded-lg p-2">
                <img className="object-cover rounded-[5px] w-[150px] h-[150px]" src={formik.values.employeeDetail.photo} alt="User Profile" />
              </div>
              <div className="w-full ml-10">
                <div className="grid grid-cols-3 gap-6 ">
                  <div className="col-span-1">
                    <InputSelect
                      title={"Shift"}
                      options={[{ label: formik.values.employeeDetail.shift, value: formik.values.employeeDetail.shift }]}
                      value={formik.values.employeeDetail.shift}
                      disabled={true}
                    />
                  </div>
                </div>
                <div className="grid grid-cols-3 gap-6 mt-5">
                  <div className="col-span-1">
                    <InputText
                      title={"First Name"}
                      label={<></>}
                      classname={"h-[58px]"}
                      disabled={true}
                      value={formik.values.employeeDetail.firstName}
                    />
                  </div>
                  <div className="col-span-1">
                    <InputText
                      title={"Last Name"}
                      label={<></>}
                      classname={"h-[58px]"}
                      disabled={true}
                      value={formik.values.employeeDetail.lastName}
                    />
                  </div>
                  <div className="col-span-1">
                    <InputText
                      title={"Employee ID"}
                      label={<></>}
                      classname={"h-[58px]"}
                      disabled={true}
                      value={formik.values.employeeDetail.employeeID}
                    />
                  </div>
                </div>
                <div className="grid grid-cols-3 gap-6 mt-5">
                  <div className="col-span-1">
                    <InputSelect
                      title={"Location"}
                      options={[{ label: formik.values.employeeDetail.location, value: formik.values.employeeDetail.location }]}
                      value={formik.values.employeeDetail.location}
                      disabled={true}
                    />
                  </div>
                  <div className="col-span-1">
                    <InputSelect
                      title={"Department"}
                      options={[{ label: formik.values.employeeDetail.department, value: formik.values.employeeDetail.department }]}
                      value={formik.values.employeeDetail.department}
                      disabled={true}
                    />
                  </div>
                  <div className="col-span-1">
                    <InputSelect
                      title={"Job Position"}
                      options={[{ label: formik.values.employeeDetail.jobPosition, value: formik.values.employeeDetail.jobPosition }]}
                      value={formik.values.employeeDetail.jobPosition}
                      disabled={true}
                    />
                  </div>
                </div>
                <div className="grid grid-cols-3 gap-6 mt-5">
                  <div className="col-span-1">
                    <InputDate
                      label={"Date"}
                      classname={" h-[58px]"}
                      placeholder={"Punch In"}
                      disabled={true}
                      value={formik.values.attendanceDetail.overtimeDate}
                    />
                  </div>
                  <div className="col-span-1">
                    <InputTime label={"Punch In Time"} required={false} disable={true} value={formik.values.attendanceDetail.punchInTime} />
                  </div>
                  <div className="col-span-1">
                    <InputTime label={"Punch Out Time"} required={false} disable={true} value={formik.values.attendanceDetail.punchOutTime} />
                  </div>
                </div>
              </div>
            </div>
          </Accordion>
        </div>

        {/* Overtime */}
        <div className="mt-8">
          <Accordion title={<div>Overtime</div>}>
            <div className="flex">
              <div className="w-full">
                <div className="grid grid-cols-3 gap-6 ">
                  <div className="col-span-1">
                    <InputSelect
                      classname={""}
                      options={[{ label: formik.values.overtimeDetail.compensationType, value: formik.values.overtimeDetail.compensationType }]}
                      title={"Compensation Type"}
                      value={formik.values.overtimeDetail.compensationType}
                      disabled={true}
                    />
                  </div>
                  <div className="col-span-1">
                    <InputSelect
                      classname={""}
                      options={[{ label: `${formik.values.overtimeDetail.overtimeDuration} Minutes`, value: `${formik.values.overtimeDetail.overtimeDuration} Minutes` }]}
                      title={"Overtime Duration"}
                      value={`${formik.values.overtimeDetail.overtimeDuration} Minutes`}
                      disabled={true}
                    />
                  </div>
                </div>
                <div className="grid grid-cols-3 gap-6 mt-5">
                  <div className="col-span-2 h-[200px]">
                    <TextArea label={"Description"} required={false} disable={true} value={formik.values.overtimeDetail.notes} />
                  </div>
                </div>
              </div>
            </div>
          </Accordion>
        </div>
      </FetchingAndError>
    </div>
  );
};

export default OvertimeApproval;
