import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import { persistReducer, persistStore } from "redux-persist";
import attendanceSettingReducer from "./slices/attendanceSettingSlice";
import attendanceDataSlice from "./slices/attendanceDataSlice";
import attendanceRequestSlice from "./slices/attendanceRequestSlice";
import authReducer from "./slices/authSlice";
import userReducer from "./slices/userSlice";
import storage from "redux-persist/lib/storage";
import thunk from "redux-thunk";

import { commonApi } from "./apis/commonApi";
import { externalApi } from "./apis/externalApi";
import { attendanceDataApi } from "./apis/attendanceDataApi";
import { attendanceOvertimeApi } from "./apis/attendanceOvertimeApi";
import { attendanceRequestApi } from "./apis/attendanceRequestApi";
import { attendanceSettingApi } from "./apis/attendanceSettingApi";

const persistConfig = {
  key: "root",
  storage,
};

const rootReducer = combineReducers({
  userData: userReducer,
  [commonApi.reducerPath]: commonApi.reducer,
  [externalApi.reducerPath]: externalApi.reducer,
  [attendanceDataApi.reducerPath]: attendanceDataApi.reducer,
  [attendanceOvertimeApi.reducerPath]: attendanceOvertimeApi.reducer,
  [attendanceRequestApi.reducerPath]: attendanceRequestApi.reducer,
  [attendanceSettingApi.reducerPath]: attendanceSettingApi.reducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware()
      .concat(thunk)
      .concat(commonApi.middleware)
      .concat(externalApi.middleware)
      .concat(attendanceDataApi.middleware)
      .concat(attendanceOvertimeApi.middleware)
      .concat(attendanceRequestApi.middleware)
      .concat(attendanceSettingApi.middleware);
  },
});

setupListeners(store.dispatch);

export const persistor = persistStore(store);
export default store;
