import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosBaseQuery } from "services/axios";

const ENDPOINT = process.env.REACT_APP_API_URL;
const API = `${ENDPOINT}/api/attendance-setting`;

const attendanceSettingApi = createApi({
  reducerPath: "attendanceSetting",
  baseQuery: axiosBaseQuery({
    baseUrl: API,
    fetchFn: async (...args) => {
      return fetch(...args);
    },
  }),
  endpoints(builder) {
    return {
      fetchAttendanceSetting: builder.query({
        query: () => {
          return {
            url: "/",
            method: "GET",
          };
        },
      }),
      addAttendanceSetting: builder.mutation({
        query: (body) => {
          return {
            url: "/create",
            method: "POST",
            body: {
              earlyPunchIn: body.earlyPunchIn,
              earlyPunchOut: body.earlyPunchOut,
              punchInDispen: body.punchInDispen,
              punchOutDispen: body.punchOutDispen,
              selectOvertimeType: body.selectOvertimeType,
              dailyOvertime: body.dailyOvertime,
              weeklyOvertime: body.weeklyOvertime,
              overtimeRounding: body.overtimeRounding,
              overtimeMultiplier: body.overtimeMultiplier,
            },
          };
        },
      }),
    };
  },
});

export const {
  useFetchAttendanceSettingQuery,
  useAddAttendanceSettingMutation,
} = attendanceSettingApi;
export { attendanceSettingApi };
