import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosBaseQuery } from "services/axios";

const ENDPOINT = process.env.REACT_APP_API_URL;
const API = `${ENDPOINT}/api/attendance-overtime`;

const attendanceOvertimeApi = createApi({
  reducerPath: "attendanceOvertime",
  baseQuery: axiosBaseQuery({
    baseUrl: API,
    fetchFn: async (...args) => {
      return fetch(...args);
    },
  }),
  endpoints(builder) {
    return {
      fetchAttendanceOvertime: builder.query({
        providesTags: (result, error) => {
          const tags = [];
          result && tags.push(...result.data.map((attendance) => ({
            type: "AttendanceOvertime",
            id: attendance._id,
          })));
          tags.push({ type: "AttendanceOvertimeAll" });
          return tags;
        },
        query: (filter) => {
          return {
            url: `/?${Object.entries(filter || {}).map((val) => !["", "undefined"].includes(val[0]) ? `${val[0]}=${val[1]}` : "").join("&")}`,
            method: "GET",
          };
        },
      }),
      fetchSingleAttendanceOvertime: builder.query({
        providesTags: (result, error) => {
          const tags = [];
          tags.push({ type: "AttendanceOvertimeAll" });
          return tags;
        },
        query: (param) => {
          return {
            url: `/${param.id}`,
            method: "GET",
          };
        },
      }),
      addAttendanceOvertime: builder.mutation({
        invalidatesTags: (result, error, body) => {
          return [{ type: "AttendanceOvertimeAll" }];
        },
        query: (body) => {
          return {
            url: "/create",
            method: "POST",
            body: {
              attendanceID: body.attendanceID,
              overtimeDuration: body.overtimeDuration,
              overtimeDate: body.overtimeDate,
              notes: body.notes,
            },
          };
        },
      }),
      updateAttendanceOvertime: builder.mutation({
        invalidatesTags: (result, error, body) => {
          return [{ type: "AttendanceOvertimeAll" }];
        },
        query: (body) => {
          return {
            url: `/${body.attendanceOvertimeID}`,
            method: "PUT",
            body: {
              bySupervisor: body.bySupervisor !== undefined ? {
                status: body.bySupervisor.status,
                comment: body.bySupervisor.comments,
              } : undefined,
              byHr: body.byHr !== undefined ? {
                status: body.byHr.status,
                comment: body.byHr.comments,
              } : undefined,
            },
          };
        },
      }),
      deleteAttendanceOvertime: builder.mutation({
        invalidatesTags: (result, error, body) => {
          return [{ type: "AttendanceOvertimeAll" }];
        },
        query: (body) => {
          return {
            url: `/${body.attendanceOvertimeID}`,
            method: "DELETE",
          };
        },
      }),
    };
  },
});

export const {
  useFetchAttendanceOvertimeQuery,
  useFetchSingleAttendanceOvertimeQuery,
  useAddAttendanceOvertimeMutation,
  useUpdateAttendanceOvertimeMutation,
  useDeleteAttendanceOvertimeMutation,
} = attendanceOvertimeApi;
export { attendanceOvertimeApi };
