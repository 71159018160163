import React from "react";

function AttendanceOvertimeSVG({color, ...otherProps}) {
  return (
    <svg
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`w-6 h-6 ${otherProps.className}`}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.8897 2.33203H5.11041C3.09641 2.33203 1.83374 3.75803 1.83374 5.77603V11.2214C1.83374 13.2394 3.08974 14.6654 5.11041 14.6654H10.8891C12.9097 14.6654 14.1671 13.2394 14.1671 11.2214V5.77603C14.1671 3.75803 12.9097 2.33203 10.8897 2.33203Z"
        stroke={color}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M10.2608 9.84194L8.00012 8.49327V5.58594"
        stroke={color}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

export default AttendanceOvertimeSVG;
