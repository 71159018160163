import axios from "axios";
import store from "store";
import { setLoginReducer, setLogoutReducer } from "store/slices/userSlice";

export const getToken = () => localStorage.getItem("accessToken");

export const axiosBaseQuery = ({ baseUrl } = { baseUrl: "" }) => async ({ url, method, body, params, headers }) => {
  try {
    const result = await axios({
      url: baseUrl + url,
      method,
      data: body,
      params,
      headers: { ...headers, Authorization: `Bearer ${getToken() || ""}` },
    });
    return { data: result.data };
  } catch (axiosError) {
    if (axiosError.response && [401, 403].includes(axiosError.response.status)) {
      localStorage.clear();
      store.dispatch(setLogoutReducer());
      setTimeout(() => window.location.assign("/dashboard/login"), 100);
    }
    const err = axiosError;
    return {
      error: {
        status: err.response?.status,
        data: err.response?.data || err.message,
      },
    };
  }
};

export default axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer ${getToken() || ""}`,
  },
});
