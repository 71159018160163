import React, { useState } from "react";
import {
  Button,
  Accordion,
  InputSelect,
  InputDate,
  InputTime,
  TextArea,
  InputText,
  ModalDialog,
} from "@bluesilodev/timhutcomponents";
import { FormikProvider, useFormik } from "formik";
import { useSelector } from "react-redux";
import { Outlet, useParams } from "react-router-dom";
import CircleCheckSVG from "assets/icon/CircleCheckSVG/CircleCheckSVG";
import CircleXMarxSVG from "assets/icon/CircleXMarkSVG/CircleXMarkSVG";
import XMarkSVG from "assets/icon/XMarkSVG/XMarkSVG";
import ProfileImage from "assets/images/profile.jpeg";
import FetchingAndError from "components/fetchingAndError";
import { useAlertApi } from "services/alert-api";
import { useFetchSingleAttendanceRequestQuery, useUpdateAttendanceRequestMutation } from "store/apis/attendanceRequestApi";
import { useFetchShiftByUserIDQuery } from "store/apis/externalApi";

const ApprovalPage = () => {
  let { userID, uID } = useParams();
  // Redux State
  const { currentRole } = useSelector((state) => state.userData);
  // Element State
  const [isEnter, setEnter] = useState("#DD7224");
  const [isModalOpen, setIsModalOpen] = useState(false);
  // Redux Toolkit
  const { data: dataShift, error: errorShift, isFetching: isFetchingShift } = useFetchShiftByUserIDQuery({ fetchShiftByUserID: userID || "" }, { refetchOnMountOrArgChange: true });
  const { data: dataRequest, error: errorAttendanceRequest, isFetching: isFetchingAttendanceRequest } = useFetchSingleAttendanceRequestQuery({ id: uID }, { refetchOnMountOrArgChange: true });
  const [approveRejectAttendanceRequest, approveRejectAttendanceRequestResult] = useUpdateAttendanceRequestMutation();
  // Formik
  const formik = useFormik({
    initialValues: {
      employeeDetail: {
        photo: "",
        shift: "",
        status: "",
        firstName: "",
        lastName: "",
        userName: "",
        employeeID: "",
        userID: userID,
        location: "",
        department: "",
        jobPosition: "",
      },
      attendanceDetail: {
      },
      requestDetail: {
        punchInDate: "",
        punchInTime: "00:00",
        punchOutDate: "",
        punchOutTime: "00:00",
        punchInGps: {
          address: "",
          lat: "",
          long: "",
          postalCode: "",
        },
        punchOutGps: {
          address: "",
          lat: "",
          long: "",
          postalCode: "",
        },
        punchInImage: "",
        punchOutImage: "",
        punchInDesc: "",
        punchOutDesc: "",
        breaks: [],
        notes: "",
        approvalStatus: {
          byHr: {
            status: "",
            comment: "",
          },
          bySupervisor: {
            status: "",
            comment: "",
          },
        },
        // Extra
        rejectReason: "",
      },
    },
    validationSchema: undefined,
    onSubmit: (values) => {
    },
  });

  React.useEffect(() => {
    if (dataRequest) {
      const punchIn = new Date(dataRequest.data.punchIn);
      const punchOut = new Date(dataRequest.data.punchOut);
      formik.setFieldValue("employeeDetail", {
        photo: dataRequest.data.employeeDetails?.photo?.[0]?.link || "",
        shift: dataRequest.data?.scheduleID || "-",
        status: dataRequest.data.attendanceDetails?.status || "",
        firstName: dataRequest.data.employeeDetails?.firstName || "",
        lastName: dataRequest.data.employeeDetails?.lastName || "",
        userName: dataRequest.data.employeeDetails?.userName || "",
        employeeID: dataRequest.data.employeeDetails?.employeeID || "",
        userID: userID,
        location: dataRequest.data.employeeDetails?.locations?.address || "",
        department: dataRequest.data.employeeDetails?.userInformation?.employeementDetail?.departments || "",
        jobPosition: dataRequest.data.employeeDetails?.userInformation?.employeementDetail?.jobPosition || "",
      });
      if (dataRequest.data.attendanceDetails) {
        const punchInActual = new Date(dataRequest.data.attendanceDetails.punchIn);
        const punchOutActual = new Date(dataRequest.data.attendanceDetails.punchOut);
        formik.setFieldValue("attendanceDetail", {
          punchInDate: `${punchInActual.getFullYear()}/${punchInActual.getMonth() + 1}/${punchInActual.getDate()}`,
          punchInTime: `${punchInActual.getHours()}:${punchInActual.getMinutes()}`,
          punchOutDate: `${punchOutActual.getFullYear()}/${punchOutActual.getMonth() + 1}/${punchOutActual.getDate()}`,
          punchOutTime: `${punchOutActual.getHours()}:${punchOutActual.getMinutes()}`,
          punchInGps: dataRequest.data.attendanceDetails.punchInGps,
          punchOutGps: dataRequest.data.attendanceDetails.punchOutGps,
          punchInImage: dataRequest.data.attendanceDetails.punchInImage,
          punchOutImage: dataRequest.data.attendanceDetails.punchOutImage,
          punchInDesc: dataRequest.data.attendanceDetails.punchInDesc,
          punchOutDesc: dataRequest.data.attendanceDetails.punchOutDesc,
          breaks: [...(dataRequest.data.attendanceDetails.breaks || [])].map((val) => {
            const breakDate = new Date(val.breakTime);
            const returnFromBreakDate = new Date(val.returnFromBreak);
            return {
              breakDate: `${breakDate.getFullYear()}/${breakDate.getMonth() + 1}/${breakDate.getDate()}`,
              returnFromBreakDate: `${returnFromBreakDate.getFullYear()}/${returnFromBreakDate.getMonth() + 1}/${returnFromBreakDate.getDate()}`,
              breakTime: `${breakDate.getHours()}:${breakDate.getMinutes()}`,
              returnFromBreak: `${returnFromBreakDate.getHours()}:${returnFromBreakDate.getMinutes()}`,
              breakImage: val.breakImage,
              returnImage: val.returnImage,
              breakDesc: val.breakDesc,
              returnDesc: val.returnDesc,
              breakGps: val.breakGps,
              returnFromBreakGps: val.returnFromBreakGps,
            };
          }),
        });
      }
      formik.setFieldValue("requestDetail", {
        punchInDate: `${punchIn.getFullYear()}/${punchIn.getMonth() + 1}/${punchIn.getDate()}`,
        punchInTime: `${punchIn.getHours()}:${punchIn.getMinutes()}`,
        punchOutDate: `${punchOut.getFullYear()}/${punchOut.getMonth() + 1}/${punchOut.getDate()}`,
        punchOutTime: `${punchOut.getHours()}:${punchOut.getMinutes()}`,
        punchInGps: dataRequest.data.punchInGps,
        punchOutGps: dataRequest.data.punchOutGps,
        punchInImage: dataRequest.data.punchInImage,
        punchOutImage: dataRequest.data.punchOutImage,
        punchInDesc: dataRequest.data.punchInDesc,
        punchOutDesc: dataRequest.data.punchOutDesc,
        breaks: [...(dataRequest.data.breaks || [])].map((val) => {
          const breakDate = new Date(val.breakTime);
          const returnFromBreakDate = new Date(val.returnFromBreak);
          return {
            breakDate: `${breakDate.getFullYear()}/${breakDate.getMonth() + 1}/${breakDate.getDate()}`,
            returnFromBreakDate: `${returnFromBreakDate.getFullYear()}/${returnFromBreakDate.getMonth() + 1}/${returnFromBreakDate.getDate()}`,
            breakTime: `${breakDate.getHours()}:${breakDate.getMinutes()}`,
            returnFromBreak: `${returnFromBreakDate.getHours()}:${returnFromBreakDate.getMinutes()}`,
            breakImage: val.breakImage,
            returnImage: val.returnImage,
            breakDesc: val.breakDesc,
            returnDesc: val.returnDesc,
            breakGps: val.breakGps,
            returnFromBreakGps: val.returnFromBreakGps,
          };
        }),
        notes: dataRequest.data.notes,
        approvalStatus: dataRequest.data.approvalStatus,
        actionDisable: dataRequest.data.actionDisable,
        // Extra
        rejectReason: "",
      });
    }
  }, [dataRequest]);

  React.useEffect(() => {
    if (dataShift && dataShift.assignShifts) {
      const findShift = dataShift?.assignShifts.find((val) => val.shift.uId === formik.values.employeeDetail.shift)?.shift?.shiftName;
      findShift && formik.setFieldValue("employeeDetail.shift", findShift || "-");
    }
  }, [dataShift, formik.values.employeeDetail.shift]);

  useAlertApi(approveRejectAttendanceRequestResult);

  const formatDate = (inputDate) => {
    const date = new Date(inputDate);
    if (isNaN(date.getTime())) {
      return "Invalid Date";
    }
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const formatTime = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
    });
  };

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleModalSubmit = () => {
    handleCloseModal();
    clickRejectAttendanceRequest();
  };

  const clickApproveAttendanceRequest = () => {
    approveRejectAttendanceRequest({
      attendanceRequestID: uID,
      ...(formik.values.requestDetail.approvalStatus.bySupervisor.status === "pending" && {
        bySupervisor: {
          status: "approved",
          message: "Ok",
        },
      }),
      ...(formik.values.requestDetail.approvalStatus.bySupervisor.status === "approved" && formik.values.requestDetail.approvalStatus.byHr.status === "pending" && {
        byHr: {
          status: "approved",
          message: "Ok",
        },
      }),
    });
  };

  const clickRejectAttendanceRequest = () => {
    approveRejectAttendanceRequest({
      attendanceRequestID: uID,
      ...(formik.values.requestDetail.approvalStatus.bySupervisor.status === "pending" && {
        bySupervisor: {
          status: "rejected",
          comments: formik.values.requestDetail.rejectReason,
        },
      }),
      ...(formik.values.requestDetail.approvalStatus.bySupervisor.status === "approved" && formik.values.requestDetail.approvalStatus.byHr.status === "pending" && {
        byHr: {
          status: "rejected",
          comments: formik.values.requestDetail.rejectReason,
        },
      }),
    });
  };

  const approvalStatus = () => {
    if (!formik.values.requestDetail.approvalStatus) return;
    const supervisorStatus = formik.values.requestDetail.approvalStatus.bySupervisor.status;
    const hrStatus = formik.values.requestDetail.approvalStatus.byHr.status;
    if (!formik.values.requestDetail.actionDisable && ["Admin", "Supervisor"].includes(currentRole) && ((supervisorStatus === "pending" && currentRole === "Supervisor") || (supervisorStatus === "approved" && hrStatus === "pending" && currentRole === "Admin"))) {
      return (
        <div className="flex flex-row items-end gap-2">
          <Button
            label={
              <div className="flex px-4 gap-1">
                <CircleXMarxSVG color={"white"} />
                <div>Reject</div>
              </div>
            }
            style={"solid"}
            onClick={handleOpenModal}
            className={"w-[230px]"}
            backgroundColor={"#DD4848"}
          />
          <Button
            label={
              <div className="flex px-4 gap-1">
                <CircleCheckSVG color={"white"} />
                <div>
                  <span>Approve</span>
                  {formik.values.requestDetail.approvalStatus.bySupervisor.status === "pending" && " By Supervisor"}
                  {formik.values.requestDetail.approvalStatus.bySupervisor.status === "approved" && formik.values.requestDetail.approvalStatus.byHr.status === "pending" && " By Admin"}
                </div>
              </div>
            }
            style={"solid"}
            className={"w-[230px]"}
            backgroundColor={"#4BD394"}
            onClick={clickApproveAttendanceRequest}
          />
        </div>
      );
    } else if (supervisorStatus === "rejected" || hrStatus === "rejected") {
      return (
        <div className="flex flex-row items-end gap-2">
          <div className="self-center text-red-500 font-bold text-right">
            Supervisor: {formik.values.requestDetail.approvalStatus.bySupervisor.comment}
            <br />
            Admin: {formik.values.requestDetail.approvalStatus.byHr.comment}
          </div>
          <Button
            label={
              <div className="flex px-4 gap-1">
                <CircleXMarxSVG color={"white"} />
                <div>Rejected</div>
              </div>
            }
            style={"solid"}
            className={"w-[230px]"}
            backgroundColor={"#FFA5A5"}
            disabled
          />
        </div>
      );
    } else if (supervisorStatus === "approved" && hrStatus === "approved") {
      return (
        <div className="flex flex-row items-end gap-2">
          <Button
            label={
              <div className="flex px-4 gap-1">
                <CircleCheckSVG color={"white"} />
                <div>Approved</div>
              </div>
            }
            style={"solid"}
            className={"w-[230px]"}
            backgroundColor={"#A5F2CE"}
            disabled
          />
        </div>
      );
    } else if (supervisorStatus === "approved" && hrStatus === "pending" && currentRole === "Supervisor") {
      return (
        <div className="flex flex-row items-end gap-2">
          <Button
            label={
              <div className="flex px-4 gap-1">
                <CircleCheckSVG color={"white"} />
                <div>Approved</div>
              </div>
            }
            style={"solid"}
            className={"w-[230px]"}
            backgroundColor={"#A5F2CE"}
            disabled
          />
        </div>
      );
    } else {
      return (
        <div className="flex flex-row items-end gap-2">
          <Button
            label={
              <div className="flex px-4 gap-1">
                <div>
                  Pending
                  {formik.values.requestDetail.approvalStatus.bySupervisor.status === "pending" && " (Supervisor)"}
                  {formik.values.requestDetail.approvalStatus.bySupervisor.status === "approved" && formik.values.requestDetail.approvalStatus.byHr.status === "pending" && " (Admin)"}
                </div>
              </div>
            }
            style={"solid"}
            className={"w-[230px]"}
            backgroundColor={"#D4D4D4"}
            disabled
          />
        </div>
      );
    }
  };

  return (
    <div className="main p-1 mt-5">
      {isModalOpen && (
        <div className="w-full  bg-white/30">
          <div class="w-full fixed z-[10] inset-0 bg-black opacity-50"></div>
          <ModalDialog
            title="Reject Attendance Request"
            onClose={handleCloseModal}
            onSubmit={handleModalSubmit}
            className={"max-h-[90%]"}
          >
            <h1 className="font-semibold mb-2">Add Note to your response</h1>
            <TextArea label={"Reason"} rows={5} name="requestDetail.rejectReason" onChange={formik.handleChange} />

            <div className="flex flex-row gap-2 justify-center mt-5">
              <Button
                onMouseEnter={() => {
                  setEnter("white");
                }}
                onMouseLeave={() => {
                  setEnter("#DD7224");
                }}
                label={
                  <div className="flex px-4 gap-1">
                    <XMarkSVG color={isEnter} />
                    <div>Cancel</div>
                  </div>
                }
                onClick={handleCloseModal}
                className={"w-[200px]"}
              />

              <Button
                label={
                  <div className="flex px-4 gap-1">
                    <CircleXMarxSVG color={"white"} />
                    <div>Reject</div>
                  </div>
                }
                onClick={handleModalSubmit}
                className={"w-[200px] bg-red-600"}
                backgroundColor={"#DD4848"}
                style={"solid"}
              />
            </div>
          </ModalDialog>
        </div>
      )}
      <FetchingAndError isFetching={isFetchingAttendanceRequest} isError={errorAttendanceRequest}>
        <div className="flex justify-between">
          <div className="flex items-center font-semibold">
            <p>Requested On 24 August 2023, 13:03</p>
          </div>
          {approvalStatus()}
        </div>

        {/* Employee Detail */}
        <div className="mt-8">
          <Accordion title={<div>Employee Details</div>}>
            <div className="flex">
              <div className="self-center border border-[#A3A3AB] rounded-lg p-2">
                <img className="object-cover rounded-[5px] w-[150px] h-[150px]" src={formik.values.employeeDetail.photo} alt="User Profile" />
              </div>
              <div className="w-full ml-10">
                <div className="grid grid-cols-3 gap-6 ">
                  <div className="col-span-1">
                    <InputSelect
                      title={"Shift"}
                      options={[{ label: formik.values.employeeDetail.shift, value: formik.values.employeeDetail.shift }]}
                      value={formik.values.employeeDetail.shift}
                      disabled={true}
                    />
                  </div>
                  <div className="col-span-1">
                    <InputText
                      title={"Status"}
                      label={<></>}
                      classname={"h-[58px]"}
                      disabled={true}
                      value={formik.values.employeeDetail.status}
                    />
                  </div>
                </div>
                <div className="grid grid-cols-3 gap-6 mt-5">
                  <div className="col-span-1">
                    <InputText
                      title={"First Name"}
                      label={<></>}
                      classname={"h-[58px]"}
                      disabled={true}
                      value={formik.values.employeeDetail.firstName}
                    />
                  </div>
                  <div className="col-span-1">
                    <InputText
                      title={"Last Name"}
                      label={<></>}
                      classname={"h-[58px]"}
                      disabled={true}
                      value={formik.values.employeeDetail.lastName}
                    />
                  </div>
                  <div className="col-span-1">
                    <InputText
                      title={"Employee ID"}
                      label={<></>}
                      classname={"h-[58px]"}
                      disabled={true}
                      value={formik.values.employeeDetail.employeeID}
                    />
                  </div>
                </div>
                <div className="grid grid-cols-3 gap-6 mt-5">
                  <div className="col-span-1">
                    <InputSelect
                      title={"Location"}
                      options={[{ label: formik.values.employeeDetail.location, value: formik.values.employeeDetail.location }]}
                      value={formik.values.employeeDetail.location}
                      disabled={true}
                    />
                  </div>
                  <div className="col-span-1">
                    <InputSelect
                      title={"Department"}
                      options={[{ label: formik.values.employeeDetail.department, value: formik.values.employeeDetail.department }]}
                      value={formik.values.employeeDetail.department}
                      disabled={true}
                    />
                  </div>
                  <div className="col-span-1">
                    <InputSelect
                      title={"Job Position"}
                      options={[{ label: formik.values.employeeDetail.jobPosition, value: formik.values.employeeDetail.jobPosition }]}
                      value={formik.values.employeeDetail.jobPosition}
                      disabled={true}
                    />
                  </div>
                </div>
              </div>
            </div>
          </Accordion>
        </div>

        {/* Punch In */}
        <div className="mt-8">
          <Accordion title={<div>Punch In</div>}>
            <div className="flex">
              <div className="w-full">
                <div className="grid grid-cols-2 gap-6 ">
                  {Object.keys(formik.values.attendanceDetail).length !== 0 && (formik.values.attendanceDetail.punchInDate !== formik.values.requestDetail.punchInDate || formik.values.attendanceDetail.punchInTime !== formik.values.requestDetail.punchInTime) && (
                    <React.Fragment>
                  <div className="col-span-1">
                    <InputDate
                      label={"Actual Punch In Date"}
                      classname={" h-[58px]"}
                      placeholder={"Punch In"}
                      disabled={true}
                      value={formatDate(formik.values.attendanceDetail.punchInDate)}
                    />
                  </div>
                  <div className="col-span-1 mr-6">
                    <InputTime
                      label={"Actual Punch In Time"}
                      required={false}
                      disable={true}
                      value={formik.values.attendanceDetail.punchInTime}
                    />
                  </div>
                    </React.Fragment>
                  )}
                  <div className="col-span-1">
                    <InputDate
                      label={"Request Punch In Date"}
                      classname={" h-[58px]"}
                      placeholder={"Punch In"}
                      disabled={true}
                      value={formatDate(formik.values.requestDetail.punchInDate)}
                    />
                  </div>
                  <div className="col-span-1 mr-6">
                    <InputTime
                      label={"Request Punch In Time"}
                      required={false}
                      disable={true}
                      value={formik.values.requestDetail.punchInTime}
                    />
                  </div>
                  {Object.keys(formik.values.attendanceDetail).length !== 0 && formik.values.attendanceDetail.punchInGps?.address !== formik.values.requestDetail.punchInGps.address && (
                    <React.Fragment>
                      <div className="col-span-2 mr-6">
                        <InputText
                          title={"Actual GPS Tracking"}
                          label={<></>}
                          classname={"h-[58px]"}
                          disabled={true}
                          value={formik.values.attendanceDetail.punchInGps?.address}
                        />
                      </div>
                    </React.Fragment>
                  )}
                  <div className="col-span-2 mr-6">
                    <InputText
                      title={"Request GPS Tracking"}
                      label={<></>}
                      classname={"h-[58px]"}
                      disabled={true}
                      value={formik.values.requestDetail.punchInGps.address}
                    />
                  </div>
                  {Object.keys(formik.values.attendanceDetail).length !== 0 && formik.values.attendanceDetail.punchInDesc !== formik.values.requestDetail.punchInDesc && (
                    <React.Fragment>
                      <div className="col-span-2 mr-6">
                        <TextArea required={false} label={"Actual Description"} disable={true} value={formik.values.attendanceDetail.punchInDesc} />
                      </div>
                    </React.Fragment>
                  )}
                  <div className="col-span-2 mr-6">
                    <TextArea required={false} label={"Request Description"} disable={true} value={formik.values.requestDetail.punchInDesc} />
                  </div>
                </div>
              </div>
              <div className="bg-gray-200 p-4 w-[400px] mr-10 rounded-md">
                <p className="text-xs font-bold">Attachment</p>
                <div className="mt-4">
                  <img
                    src={formik.values.requestDetail.punchInImage}
                    alt="Attachment"
                    className="w-[300px] h-[300px] rounded-lg"
                  />
                </div>
              </div>
            </div>
          </Accordion>
        </div>

        {/* Punch Out */}
        <div className="mt-8">
          <Accordion title={<div>Punch out</div>}>
            <div className="flex">
              <div className="w-full">
                <div className="grid grid-cols-2 gap-6 ">
                  {Object.keys(formik.values.attendanceDetail).length !== 0 && (formik.values.attendanceDetail.punchOutDate !== formik.values.requestDetail.punchOutDate || formik.values.attendanceDetail.punchOutTime !== formik.values.requestDetail.punchOutTime) && (
                    <React.Fragment>
                      <div className="col-span-1">
                        <InputDate
                          label={"Actual Punch Out Date"}
                          classname={" h-[58px]"}
                          placeholder={"Punch In"}
                          disabled={true}
                          value={formatDate(formik.values.attendanceDetail.punchOutDate)}
                        />
                      </div>
                      <div className="col-span-1 mr-6">
                        <InputTime label={"Actual Punch Out Time"} required={false} disable={true} value={formik.values.attendanceDetail.punchOutTime} />
                      </div>
                    </React.Fragment>
                  )}
                  <div className="col-span-1">
                    <InputDate
                      label={"Request Punch Out Date"}
                      classname={" h-[58px]"}
                      placeholder={"Punch Out"}
                      disabled={true}
                      value={formatDate(formik.values.requestDetail.punchOutDate)}
                    />
                  </div>
                  <div className="col-span-1 mr-6">
                    <InputTime label={"Request Punch Out Time"} required={false} disable={true} value={formik.values.requestDetail.punchOutTime} />
                  </div>

                  {Object.keys(formik.values.attendanceDetail).length !== 0 && formik.values.attendanceDetail.punchOutGps?.address !== formik.values.requestDetail.punchOutGps.address && (
                    <React.Fragment>
                      <div className="col-span-2 mr-6">
                        <InputText
                          title={"Actual GPS Tracking"}
                          label={<></>}
                          classname={"h-[58px]"}
                          disabled={true}
                          value={formik.values.attendanceDetail.punchOutGps?.address}
                        />
                      </div>
                    </React.Fragment>
                  )}
                  <div className="col-span-2 mr-6">
                    <InputText
                      title={"Request GPS Tracking"}
                      label={<></>}
                      classname={"h-[58px]"}
                      disabled={true}
                      value={formik.values.requestDetail.punchOutGps.address}
                    />
                  </div>
                  {Object.keys(formik.values.attendanceDetail).length !== 0 && formik.values.attendanceDetail.punchOutDesc !== formik.values.requestDetail.punchOutDesc && (
                    <React.Fragment>
                      <div className="col-span-2 mr-6">
                        <TextArea label={"Actual Description"} required={false} disable={true} value={formik.values.attendanceDetail.punchOutDesc} />
                      </div>
                    </React.Fragment>
                  )}
                  <div className="col-span-2 mr-6">
                    <TextArea label={"Request Description"} required={false} disable={true} value={formik.values.requestDetail.punchOutDesc} />
                  </div>
                </div>
              </div>
              <div className="bg-gray-200 p-4 w-[400px] mr-10 rounded-md">
                <p className="text-xs font-bold">Attachment</p>
                <div className="mt-4">
                  <img
                    src={formik.values.requestDetail.punchOutImage}
                    alt="Attachment"
                    className="w-[300px] h-[300px] rounded-lg"
                  />
                </div>
              </div>
            </div>
          </Accordion>
        </div>

        {/* Break*/}
        {Object.keys(formik.values.attendanceDetail).length !== 0 && formik.values.attendanceDetail.breaks.length === formik.values.requestDetail.breaks.length && formik.values.attendanceDetail.breaks.map((breakItem, index) => {
          return (
          <div className="mt-8">
            <Accordion title={<div>Break #1</div>}>
              <div className="flex">
                <div className="w-full">
                  <div className="grid grid-cols-2 gap-6 ">
                    {Object.keys(formik.values.attendanceDetail).length !== 0 && (breakItem.breakDate !== formik.values.requestDetail.breaks[index].breakDate || breakItem.breakTime !== formik.values.requestDetail.breaks[index].breakTime) && (
                      <React.Fragment>
                        <div className="col-span-1">
                          <InputDate
                            label={"Actual Break Date"}
                            classname={" h-[58px]"}
                            placeholder={"Punch In"}
                            value={formatDate(breakItem.breakDate)}
                            disabled={true}
                          />
                        </div>
                        <div className="col-span-1 mr-6">
                          <InputTime label={"Actual Break Time"} required={false} disable={true} value={breakItem.breakTime}/>
                        </div>
                      </React.Fragment>
                    )}
                    <div className="col-span-1">
                      <InputDate
                        label={"Request Break Date"}
                        classname={" h-[58px]"}
                        placeholder={"Punch In"}
                        value={formatDate(formik.values.requestDetail.breaks[index].breakDate)}
                        disabled={true}
                      />
                    </div>
                    <div className="col-span-1 mr-6">
                      <InputTime label={"Request Break Time"} required={false} disable={true} value={formik.values.requestDetail.breaks[index].breakTime} />
                    </div>
  
                    {Object.keys(formik.values.attendanceDetail).length !== 0 && breakItem.breakGps?.address !== formik.values.requestDetail?.breaks?.[index]?.breakGps?.address && (
                      <div className="col-span-2 mr-6">
                        <InputText
                          title={"Request GPS Tracking"}
                          label={<></>}
                          classname={"h-[58px]"}
                          disabled={true}
                          value={breakItem.breakGps?.address}
                        />
                      </div>
                    )}
                    <div className="col-span-2 mr-6">
                      <InputText
                        title={"Request GPS Tracking"}
                        label={<></>}
                        classname={"h-[58px]"}
                        disabled={true}
                        value={formik.values.requestDetail?.breaks?.[index]?.breakGps?.address}
                      />
                    </div>
                    {Object.keys(formik.values.attendanceDetail).length !== 0 && breakItem.breakDesc !== formik.values.requestDetail.breaks[index].breakDesc && (
                      <div className="col-span-2 mr-6">
                        <TextArea label={"Actual Description"} required={false} disable={true} value={breakItem.breakDesc} />
                      </div>
                    )}
                    <div className="col-span-2 mr-6">
                      <TextArea label={"Request Description"} required={false} disable={true} value={formik.values.requestDetail.breaks[index].breakDesc} />
                    </div>
                  </div>
                </div>
                <div className="bg-gray-200 p-4 w-[400px] mr-10 rounded-md">
                  <p className="text-xs font-bold">Attachment</p>
                  <div className="mt-4">
                    <img
                      src={breakItem.breakImage}
                      alt="Attachment"
                      className="w-[300px] h-[300px] rounded-lg"
                    />
                  </div>
                </div>
              </div>
            </Accordion>
          </div>
          )
        })}

        {/* Resume*/}
        {Object.keys(formik.values.attendanceDetail).length !== 0 && formik.values.attendanceDetail.breaks.length === formik.values.requestDetail.breaks.length && formik.values.attendanceDetail.breaks.map((breakItem, index) => {
          return (
          <div className="mt-8 mb-8">
            <Accordion title={<div>Resume #1</div>}>
              <div className="flex">
                <div className="w-full">
                  <div className="grid grid-cols-2 gap-6 ">
                    {Object.keys(formik.values.attendanceDetail).length !== 0 && (breakItem.returnFromBreakDate !== formik.values.requestDetail.breaks[index].returnFromBreakDate || breakItem.returnFromBreak !== formik.values.requestDetail.breaks[index].returnFromBreak) && (
                      <React.Fragment>
                        <div className="col-span-1">
                          <InputDate
                            label={"Actual Break Date"}
                            classname={" h-[58px]"}
                            placeholder={"Punch In"}
                            value={formatDate(breakItem.returnFromBreakDate)}
                            disabled={true}
                          />
                        </div>
                        <div className="col-span-1 mr-6">
                          <InputTime label={"Actual Break Time"} required={false} disable={true} value={breakItem.returnFromBreak} />
                        </div>
                      </React.Fragment>
                    )}
                    <div className="col-span-1">
                      <InputDate
                        label={"Resume Date"}
                        classname={" h-[58px]"}
                        placeholder={"Punch In"}
                        disabled={true}
                        value={formatDate(formik.values.requestDetail.breaks[index].returnFromBreakDate)}
                      />
                    </div>
                    <div className="col-span-1 mr-6">
                      <InputTime label={"Resume Time"} required={false} disable={true}  value={formik.values.requestDetail.breaks[index].returnFromBreak} />
                    </div>
  
                    {Object.keys(formik.values.attendanceDetail).length !== 0 && breakItem.returnFromBreakGps?.address !== formik.values.requestDetail?.breaks?.[index]?.returnFromBreakGps?.address && (
                      <div className="col-span-2 mr-6">
                        <InputText
                          title={"Request GPS Tracking"}
                          label={<></>}
                          classname={"h-[58px]"}
                          disabled={true}
                          value={breakItem.returnFromBreakGps?.address}
                        />
                      </div>
                    )}
                    <div className="col-span-2 mr-6">
                      <InputText
                        title={"Request GPS Tracking"}
                        label={<></>}
                        classname={"h-[58px]"}
                        disabled={true}
                        value={formik.values.requestDetail?.breaks?.[index]?.returnFromBreakGps?.address}
                      />
                    </div>
                    {Object.keys(formik.values.attendanceDetail).length !== 0 && breakItem.returnDesc !== formik.values.requestDetail.breaks[index].returnDesc && (
                      <div className="col-span-2 mr-6">
                        <TextArea label={"Actual Description"} required={false} disable={true} value={breakItem.returnDesc} />
                      </div>
                    )}
                    <div className="col-span-2 mr-6">
                      <TextArea label={"Request Description"} required={false} disable={true}  value={formik.values.requestDetail.breaks[index].returnDesc} />
                    </div>
                  </div>
                </div>
                <div className="bg-gray-200 p-4 w-[400px] mr-10 rounded-md">
                  <p className="text-xs font-bold">Attachment</p>
                  <div className="mt-4">
                    <img
                      src={breakItem.returnImage}
                      alt="Attachment"
                      className="w-[300px] h-[300px] rounded-lg"
                    />
                  </div>
                </div>
              </div>
            </Accordion>
          </div>
          )
        })}
      </FetchingAndError>
    </div>
  );
};

export default ApprovalPage;
